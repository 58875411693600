import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Products from "./components/Products";
import NavBar from "./components/Navbar";
import Contact from "./components/Contact";
import Testimonials from "./components/Testimonials";
import Financing from "./components/Financing";
import Footer from "./components/Footer";
import CarHauler from "./components/CarHauler";
import Dump from "./components/Dump";
import Enclosed from "./components/Enclosed";
import Equipment from "./components/Equipment";
import Gooseneck from "./components/Gooseneck";
import PintleHitch from "./components/PintleHitch";
import Utility from "./components/Utility";
import Header from "./components/Header";
import ProductList from "./components/ProductList";
import FeaturedDeals from "./components/FeaturedDeals";
import HomePage from "./components/HomePage";
import Footer2 from "./components/Footer2";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function App() {
  return (
    <>
      <NavBar></NavBar>

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/testimonials" element={<Testimonials />} />
        <Route exact path="/financing" element={<Financing />} />
        <Route exact path="/carhauler" element={<CarHauler />} />
        <Route exact path="/dump" element={<Dump />} />
        <Route exact path="/equipment" element={<Equipment />} />
        <Route exact path="/enclosed" element={<Enclosed />} />
        <Route exact path="/gooseneck" element={<Gooseneck />} />
        <Route exact path="/pintlehitch" element={<PintleHitch />} />
        <Route exact path="/utility" element={<Utility />} />
      </Routes>

      <Footer></Footer>

      {/* <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/products" element={<ProductList />} />
        <Route exact path="/featured" element={<FeaturedDeals />} />
      </Routes>

      <Footer2></Footer2> */}
    </>
  );
}
export default App;
