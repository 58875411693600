import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"; // Import additional components

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function Contact() {
  return (
    <>
      <div className="my-5">
        <h1>Contact Us</h1>
      </div>
      <Container>
        <p className="text-center">
          We would love to hear from you! Let us know how we can assist you with
          your trailer needs.
        </p>
        <Row className="mb-5">
          <Col xs={12} md={6} className="my-auto">
            <Card className="mt-4">
              <Card.Body>
                <Card.Title>Contact Information</Card.Title>
                <Card.Text>
                  <FontAwesomeIcon icon={faHouse} />
                  <span className="m-2">
                    Address: 3350 N. Mills River Rd., Mills River, NC
                  </span>{" "}
                  <br />
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="m-2">(828) 230-7702 </span> <br />
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span className="m-2">
                    info@mountaintrailerventures.com
                  </span>{" "}
                  <br />
                  <a href="https://www.facebook.com/mountaintrailerventures">
                    <FontAwesomeIcon icon={faFacebook} /> Facebook
                  </a>
                  <br />
                  <a href="https://www.instagram.com/mountaintrailerventures">
                    <FontAwesomeIcon icon={faInstagram} /> Instagram
                  </a>
                  <br />
                  <a href="https://www.linkedin.com/company/mountaintrailerventures">
                    <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className="my-auto">
            <Form>
              <Form.Group controlId="email">
                <Form.Label>Your Email:</Form.Label>
                <Form.Control type="email" placeholder="Enter email" required />
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label>Your Message:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Write your message here"
                  required
                  className="mb-3"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Send
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
