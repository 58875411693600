import CalDeynah from "./Assets/TestimonialPics/depositphotos_218271242-stock-photo-smiling-bearded-man-driving-pickup.jpg";

const testimonial = [
  // {
  //   name: "Anna Deynah",
  //   jobTitle: "UX Designer",
  //   quote:
  //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.",
  //   rating: 4.5,
  //   avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp",
  // },
  // {
  //   name: "Cal Deynah",
  //   jobTitle: "Business Owner",
  //   quote:
  //     '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Cal D.',
  //   rating: 3.5,
  //   avatarUrl: { CalDeynah }, // Assuming review1 holds a valid image URL
  // },
  {
    name: "Shane F.",
    jobTitle: "Business Owner",
    quote:
      '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Shane F.',
    rating: 5,
    avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp",
  },
  {
    name: "Patrick M.",
    jobTitle: "Off-Road Aventurer",
    quote:
      "\"Absolutely thrilled with my rugged trailer purchase from Mountain Trail Ventures! Their range is a treasure trove for off-road enthusiasts, prices that won't break the bank, and service that's as tough as the terrain. If you're gearing up for adventure, these guys are the real deal!\" - Patrick M.",
    rating: 5,
    avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp",
  },
  // {
  //   name: "Kimberly Olsen",
  //   jobTitle: "Photographer",
  //   quote:
  //     '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Cal D.',
  //   rating: 3,
  //   avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
  // },
  // {
  //   name: "Maggie Marx",
  //   jobTitle: "UX Designer",
  //   quote:
  //     '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Cal D.',
  //   rating: 3.5,
  //   avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp",
  // },
  // {
  //   name: "Bob Barker",
  //   jobTitle: "Web Developer",
  //   quote:
  //     '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Cal D.',
  //   rating: 4,
  //   avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(8).webp",
  // },
  // {
  //   name: "Maria Kate",
  //   jobTitle: "Photographer",
  //   quote:
  //     '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Cal D.',
  //   rating: 3.5,
  //   avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(7).webp",
  // },
  // {
  //   name: "Hector Ramirez",
  //   jobTitle: "Web Developer",
  //   quote:
  //     '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Cal D.',
  //   rating: 3.5,
  //   avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(4).webp",
  // },
  // {
  //   name: "Amber Reese",
  //   jobTitle: "UI Designer",
  //   quote:
  //     '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Cal D.',
  //   rating: 5,
  //   avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(5).webp",
  // },
];

export default testimonial;
