import React from "react";
import aboutImage from "./Assets/Misc/Photo May 06 2024, 5 18 32 PM.jpg";
import { Container } from "react-bootstrap";

function About() {
  return (
    <>
      <div className="pt-5">
        <h1>About Us</h1>
      </div>

      <div className="text-center pt-5">
        <img
          style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
          src={aboutImage}
          alt="Gooseneck trailer"
        />

        <h2
          className="my-5 text-warning"
          style={{
            fontSize: "2rem",
            fontWeight: "normal",
            textShadow: "2px 2px 2px rgba(255, 255, 255, 0.25)",
          }}
        >
          "Trailers as Rugged as Our Western NC Terrain: Explore with Mountain
          Trailer Ventures!"
        </h2>

        <Container>
          <p className="lead">
            We are a veteran-owned and operated trailer company, based out of
            the scenic Western North Carolina. At Mountain Trailer Ventures, we
            bring together our dedication to excellence and our commitment to
            serving our customers with the highest quality trailers on the
            market. With a foundation built on the values instilled by our
            military service, we approach every aspect of our business with
            integrity, precision, and a relentless pursuit of customer
            satisfaction. Whether you're hauling equipment for work or embarking
            on an adventure with family and friends, our trailers are designed
            to meet your needs and exceed your expectations. We take pride in
            our craftsmanship, attention to detail, and the durability of our
            products, ensuring that they stand the test of time. Thank you for
            considering Mountain Trailer Ventures for your trailer needs.
          </p>
        </Container>
      </div>
    </>
  );
}

export default About;
