import React from "react";
import TrailerCards from "./TrailerCards";
import CTA from "./CTA";

function Products() {
  return (
    <>
      <div className="pt-5">
        <h1>Products</h1>
      </div>
      <div className="pt-5">
        <TrailerCards />

        <CTA />
      </div>
      {/* <div className="my-5 text-center">
        <h1>Products</h1>
      </div>

      <p>
        We have three main categories of trailers: gooseneck trailers, equipment
        trailers, and utility trailers. Each category has different models and
        features to suit your needs. You can browse our inventory online or
        visit our showroom to see them in person. We also offer financing and
        delivery options for your convenience. Here are some of the trailers we
        have in stock:
      </p>

      <ul>
        <li>
          Gooseneck Trailers: These trailers have a hitch that connects to the
          bed of a truck, allowing for more stability and maneuverability. They
          are ideal for hauling heavy loads, such as vehicles, livestock, or
          construction materials. Some of the models we have are:
          <ul>
            <li>
              MTV-20GN: A 20-foot gooseneck trailer with a 14,000-lb capacity.
            </li>
            <li>
              MTV-24GN: A 24-foot gooseneck trailer with a 16,000-lb capacity.
            </li>
          </ul>
        </li>
        <li>
          Equipment Trailers: These trailers are designed to transport various
          types of equipment, such as UTV's, tractors, mowers, generators, or
          compressors. They have ramps, tie-downs, and brakes to ensure safe and
          easy loading and unloading. Some of the models we have are:
          <ul>
            <li>
              MTV-16ET: A 16-foot equipment trailer with a 7,000-lb capacity.
            </li>
            <li>
              MTV-18ET: A 18-foot equipment trailer with a 10,000-lb capacity.
            </li>
            <li>
              MTV-20ET: A 20-foot equipment trailer with a 12,000-lb capacity.
            </li>
          </ul>
        </li>
        <li>
          Utility Trailers: These trailers are versatile and can be used for a
          variety of purposes, such as moving furniture, appliances, or
          landscaping materials. They have a flatbed, a tailgate, and a
          removable mesh. Some of the models we have are:
          <ul>
            <li>
              MTV-5X8UT: A 5x8-foot utility trailer with a 2,000-lb capacity.
            </li>
            <li>
              MTV-6X10UT: A 6x10-foot utility trailer with a 3,000-lb capacity.
            </li>
            <li>
              MTV-6X12UT: A 6x12-foot utility trailer with a 4,000-lb capacity.
            </li>
          </ul>
        </li>
      </ul> */}
    </>
  );
}

export default Products;
