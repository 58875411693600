import React from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import CTA from "./CTA";
import gooseneck1 from "./Assets/Gooseneck Trailer Pics/Photo Apr 27 2024, 5 08 56 PM.jpg";
import gooseneck2 from "./Assets/Gooseneck Trailer Pics/Photo Apr 27 2024, 5 09 37 PM.jpg";
import gooseneck3 from "./Assets/Gooseneck Trailer Pics/Photo Apr 27 2024, 5 10 15 PM.jpg";
import gooseneck4 from "./Assets/Gooseneck Trailer Pics/Photo Apr 27 2024, 5 12 30 PM.jpg";
import gooseneck5 from "./Assets/Gooseneck Trailer Pics/Photo Apr 27 2024, 5 10 52 PM.jpg";

function Gooseneck() {
  return (
    <>
      <div className="pt-5">
        <h1>Gooseneck Trailers</h1>
      </div>

      <Carousel>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={gooseneck1}
            alt="Gooseneck trailer"
          />
          {/* <Carousel.Caption>
            <h3>Gooseneck Trailers</h3>
            <p>The best choice for heavy-duty hauling.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={gooseneck2}
            alt="Equipment trailer"
          />
          {/* <Carousel.Caption>
            <h3>Equipment Trailers</h3>
            <p>Perfect for transporting machinery and tools.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={gooseneck3}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={gooseneck4}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={gooseneck5}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>

      <div className="my-5" style={{ minHeight: "300px" }}>
        <Container className="w-75 mb-4">
          <div className="gooseneck-trailer-description text-center">
            <h2 className="text-primary">
              Haul with Stability - Gooseneck Trailers
            </h2>
            <p className="lead">
              Our top-of-the-line gooseneck trailers are designed to provide
              stability and efficiency when hauling heavy loads. Whether you're
              in the construction business or need a dependable way to transport
              large equipment across long distances, we have the perfect trailer
              for your needs.
            </p>
          </div>
        </Container>
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary">Size and Weight Specs:</h2>
              <p>
                Gooseneck trailers come in various sizes to accommodate
                different hauling requirements. Here's an overview of common
                configurations:
              </p>
              <ul>
                {/* <li>
                  **Single-axle gooseneck trailer:** Typically 20' to 24' long
                  and can handle loads up to 14,000 lbs.
                </li> */}
                <li>
                  **Tandem-axle gooseneck trailer:** Usually 20' to 40' long and
                  capable of handling loads up to 23,000 lbs., providing added
                  stability and capacity for heavier loads.
                </li>
                <li>
                  **Triple-axle gooseneck trailer:** Offers increased load
                  capacity and stability, ideal for transporting large equipment
                  or multiple loads.
                </li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Common Specifications:</h2>
              <ul>
                <li>Heavy-duty steel frame construction for durability</li>
                <li>Pressure-treated wood deck for longevity</li>
                <li>LED lighting for visibility and safety</li>
                <li>Ramps for easy loading and unloading</li>
                <li>Electric brakes for reliable stopping power</li>
                <li>Adjustable gooseneck for proper towing height</li>
                <li>Available in various deck lengths and widths</li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Optional Features:</h2>
              <ul>
                <li>Spare tire and mount</li>
                <li>Toolbox for storage of equipment and accessories</li>
                <li>Winch for loading heavy machinery</li>
                <li>Dovetail for easier equipment loading</li>
                <li>Upgrade to radial tires for improved durability</li>
                <li>Custom paint and branding options</li>
                <li>Hydraulic lift for raising and lowering equipment</li>
              </ul>
            </Col>
          </Row>
          <Container>
            <div>
              <h4 className="text-primary">
                82x20 GN Manual Tilt Equipment Trailer, 14K GWR 4' Stationary 16
                Tilt Deck 82x22 GN Manual Tilt Equipment Trailer, 14K GVWR 5'
                Stationary 17 Tilt Deck 82x24 GN Manual Tilt Equipment Traile,
                14K GVWR 6 Stationary 18' Tilt Deck
              </h4>
              <p>
                Specifications: (2) Easy Lube 7K GAWR Brake Axles Multi-Leaf
                Slipper Springs 2 5/16" Ball Adjustable GN Coupler (2) 12K Jacks
                Stationary Deck and Tilt Deck See Dimensions Above 6" Channel
                Frame and Wrap Tongue 3" Channel Crossmembers on 16" Centers 8"
                Channel GN 3' Steel Dovetail/Beavertail Stake Pockets and Rub
                Rail Tool/Strap Box Between Jacks HD Tread Plate Fenders
                ST235/80R 16 Radial Tires and Silver Modular Rims Spare Tire
                Mount on GN LED Lights in Metal Boxes DOT Approved Break-Away
                Paint Color? Pinstripe Color? Spare Tire Added?
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                102X20+5 GN Equipment Trailer, 14K GWR 102X25+5 GN Equipment
                Trailer, 14K GWR 102X30+5 GN Equipment Trailer, 14K GWR 102X35+5
                GN Equipment Trailer, 14K GWR
              </h4>

              <p>
                Specifications: (2) Easy Lube 7K Brake Axles Multi Leaf Slipper
                Springs 12" Beam Main Frame and Gooseneck 4" Torsion Tube 3"
                Channel Crossmembers on 24" Centers 6" Channel Side Rails Stake
                Pockets and Rub Rail Step Bar and Handle on Both Sides of
                Trailer 2 5/16" Ball Adjustable Gooseneck Coupler (2) 12K Drop
                Leg Front Pin Jacks ST235/80R16 Radial Tires and Silver Modular
                Rims 10 Ply Tires Spare Tire Mount on GN Stand Up Equipment
                Ramps 2x8 Treated Wood Floor Lockable Tool Box Between Jacks on
                Front of Trailer 2 x 6 Oval LED Lights in Metal Boxes DOT
                Approved Break-Away Black, Red or Gray Paint Specify GVWR (14K
                or 16K)
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                102x20+5 GN, 23K GWR 102x25+5 GN, 23K GWR 102x30+5 GN, 23K GVWR
                102x35+5 GN, 23K GWR
              </h4>

              <p>
                Specifications: (2) 10K Oil Bath Brake Axles Multi Leaf Slipper
                Springs 12" Beam Main Frame and Gooseneck 4" Torsion Tube 3"
                Channel Crossmembers with 6" Channel Side Rails 2 5/16" Ball
                Adjustable Gooseneck Coupler (2) 12K Drop Leg Front Pin Jacks
                ST235/80R16 Dual Tires on Silver Modular Rims Spare Tire Mount
                on GN Stand-up Equipment Ramps Step Bar and Handle on Both Sides
                of Trailer 2x8 Treated Wood Floor Lockable Tool Box Between
                Jacks on Front of Trailer Stake Pockets and Rub Rail 2 x 6 Oval
                LED Lights in Metal Boxes DOT Approved Break-Away
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                102X20+5 GN Equipment Trailer, 20K GVWR 102X25+5 GN Equipment
                Trailer, 20K GVWR 102X30+5 GN Equipment Trailer, 20K GWR
                102X35+5 GN Equipment Trailer, 20K GVWR
              </h4>

              <p>
                Specifications: (2) 10K Dexter Axles, Oil Bath, Electric Drum
                Brakes Multi Leaf Slipper Springs 12" Beam Main Frame and
                Gooseneck 4" Torsion Tube 3" Channel Crossmembers on 24" Centers
                6" Channel Side Rails Stake Pockets and Rub Rail Step Bar and
                Handle on Both Sides of Trailer 25/16" Ball Adjustable Gooseneck
                Coupler (2) 12K Drop Leg Front Pin Jacks ST235/80R16 Dual Tires
                on Silver Rims (10 Ply) Spare Tire Mount on GN Stand Up
                Equipment Ramps 2x8 Treated Wood Floor Lockable Tool Box Between
                Jacks on Front of Trailer 2 x 6 Oval LED Lights in Metal Boxes
                DOT Approved Break-Away Black, Red or Gray Paint Specify GVWR
                (20K or 23K){" "}
              </p>
            </div>
          </Container>

          <p className="lead text-center text-primary my-5">
            Contact us today to discuss your specific hauling needs and receive
            a quote for your ideal gooseneck trailer!
          </p>
        </Container>

        <CTA />
      </div>
    </>
  );
}

export default Gooseneck;
