import React from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import CTA from "./CTA";
import dump1 from "./Assets/Utility Trailer Pics/Photo Apr 23 2024, 4 29 24 PM.jpg";
import dump2 from "./Assets/Utility Trailer Pics/Photo Apr 23 2024, 4 31 12 PM.jpg";

function Dump() {
  return (
    <>
      <div className="pt-5 pb-2">
        <h1>Dump Trailers</h1>
      </div>

      <Carousel>
        {/* <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={dump1}
            alt="Gooseneck trailer"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={dump2}
            alt="Equipment trailer"
          />
        </Carousel.Item> */}
      </Carousel>

      <div className="my-5" style={{ minHeight: "300px" }}>
        <Container className="w-75 mb-4">
          <div className="car-hauler-description text-center">
            <h2 className="text-primary">
              Designed to Handle Your Heavy Loads - Dump Trailers
            </h2>
            <p className="lead">
              Our top-of-the-line dump trailers are built to securely and
              efficiently transport your materials. Whether you're a
              professional contractor or need a reliable way to move heavy loads
              across long distances, we have the perfect trailer for you.
            </p>
          </div>
        </Container>
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary">Size and Weight Specs:</h2>
              <p>
                Dump trailers come in various sizes to suit different hauling
                needs. Here's an overview of common configurations:
              </p>
              <ul>
                <li>
                  **Single-axle dump trailer:** Typically 10' to 14' long and
                  can handle loads up to 7,000 lbs.
                </li>
                <li>
                  **Tandem-axle dump trailer:** Usually 14' to 18' long and
                  capable of handling loads up to 10,000 lbs., providing
                  enhanced stability for heavier materials.
                </li>
                <li>
                  **Gooseneck dump trailer:** Offers increased load capacity and
                  stability, ideal for heavy-duty hauling requirements.
                </li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Common Specifications:</h2>
              <ul>
                <li>Heavy-duty steel frame construction for durability</li>
                <li>Corrosion-resistant steel bed for longevity</li>
                <li>LED lighting for enhanced visibility</li>
                <li>High-quality hydraulic system for efficient dumping</li>
                <li>Electric brakes for reliable stopping power</li>
                <li>Manual or automatic tarp system for load protection</li>
                <li>Available in various bed lengths and widths</li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Optional Features:</h2>
              <ul>
                <li>Spare tire and mount</li>
                <li>Side extensions for increased capacity</li>
                <li>Toolbox for convenient storage of tools and equipment</li>
                <li>Remote control for hydraulic operation</li>
                <li>Aluminum wheels for reduced trailer weight</li>
                <li>Heavy-duty ramps for loading equipment</li>
                <li>Underbody tool storage compartments</li>
              </ul>
            </Col>
          </Row>
          <p className="lead text-center text-primary my-5">
            Contact us today to discuss your specific hauling needs and receive
            a quote for your ideal dump trailer!
          </p>
        </Container>
        <CTA />
      </div>
    </>
  );
}

export default Dump;
