import React from "react";
import HeroSection from "./HeroSection";
import LinksContainer from "./LinksContainer";
import ManuHighlights from "./ManuHighlights";
import CompanyMission from "./CompanyMission";
import TextBanner from "./TextBanner";
import CustomBuild from "./CustomBuild";
import Support from "./Support";
import CTA from "./CTA";

function Home() {
  return (
    <>
      <HeroSection />
      <LinksContainer />
      <TextBanner />
      <ManuHighlights />
      <CustomBuild />
      <CompanyMission />
      <Support />
      <CTA />
    </>
  );
}

export default Home;
