import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <>
      <div className="m-auto shadow border border-primary rounded w-50 text-center mb-5">
        <Row>
          <Col>
            <Container className="my-4">
              <h2 className="text-danger">Find Your Perfect Trailer Today!</h2>
              <p className="lead">
                Looking for the ideal trailer to match your hauling needs and
                budget?{" "}
                <Link to="/contact">
                  <h3 className="d-inline">Contact Us</h3>
                </Link>{" "}
                to hear more about our extensive inventory, explore trailer
                features, and calculate financing options - all from the comfort
                of your couch!
              </p>
              <Link to="/contact">
                <Button variant="primary" className="text-white">
                  Click Here For Our Contact Information
                </Button>
              </Link>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CTA;
