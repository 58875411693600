import React from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import CTA from "./CTA";
import enclosed1 from "./Assets/EnclosedTrailerPics/Photo Apr 27 2024, 5 11 34 PM.jpg";
import enclosed2 from "./Assets/EnclosedTrailerPics/Photo Apr 23 2024, 4 24 02 PM.jpg";
import enclosed3 from "./Assets/EnclosedTrailerPics/Photo Apr 23 2024, 4 25 13 PM.jpg";
import enclosed4 from "./Assets/EnclosedTrailerPics/Photo Apr 23 2024, 4 26 20 PM.jpg";

function Enclosed() {
  return (
    <>
      <div className="pt-5">
        <h1>Enclosed Trailers</h1>
      </div>

      <Carousel>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={enclosed1}
            alt="Gooseneck trailer"
          />
          {/* <Carousel.Caption>
            <h3>Gooseneck Trailers</h3>
            <p>The best choice for heavy-duty hauling.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={enclosed2}
            alt="Equipment trailer"
          />
          {/* <Carousel.Caption>
            <h3>Equipment Trailers</h3>
            <p>Perfect for transporting machinery and tools.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={enclosed4}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={enclosed3}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>

      <div className="my-5" style={{ minHeight: "300px" }}>
        <Container className="w-75 mb-4">
          <div className="enclosed-trailer-description text-center">
            <h2 className="text-primary">
              Securely Transport Your Valuables - Enclosed Trailers
            </h2>
            <p className="lead">
              Our top-of-the-line enclosed trailers are crafted to safely and
              effectively transport your goods. Whether you're a professional
              transporter or require a protected way to move items over long
              distances, we have the perfect trailer for your needs.
            </p>
          </div>
        </Container>
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary">Size and Weight Specs:</h2>
              <p>
                Enclosed trailers are available in various sizes to accommodate
                different hauling requirements. Here's an overview of common
                configurations:
              </p>
              <ul>
                <li>
                  **Single-axle enclosed trailer:** Typically 8' to 12' long and
                  can handle loads up to 3,000 lbs.
                </li>
                <li>
                  **Tandem-axle enclosed trailer:** Usually 12' to 20' long and
                  capable of handling loads up to 7,000 lbs., providing added
                  stability and capacity for heavier items.
                </li>
                <li>
                  **Gooseneck enclosed trailer:** Offers increased load capacity
                  and stability, ideal for transporting larger items or multiple
                  loads.
                </li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Common Specifications:</h2>
              <ul>
                <li>Durable steel frame construction for longevity</li>
                <li>Aluminum or steel exterior for weather resistance</li>
                <li>LED lighting for visibility and safety</li>
                <li>Side access door for easy loading and unloading</li>
                <li>Electric brakes for reliable stopping power</li>
                <li>Interior tie-down points for securing cargo</li>
                <li>Available in various interior heights and widths</li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Optional Features:</h2>
              <ul>
                <li>Spare tire and mount</li>
                <li>Interior shelving and storage solutions</li>
                <li>Roof vent and insulation for climate control</li>
                <li>Ramp door for convenient access</li>
                <li>Security features such as locks and alarms</li>
                <li>Custom graphics and branding options</li>
                <li>Upgrade to torsion axles for smoother towing</li>
              </ul>
            </Col>
          </Row>
          <p className="lead text-center text-primary my-5">
            Contact us today to discuss your specific hauling needs and receive
            a quote for your ideal enclosed trailer!
          </p>
        </Container>

        <CTA />
      </div>
    </>
  );
}

export default Enclosed;
