import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import testimonials from "./testimonial";

function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 3);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 3, 0));
  };

  return (
    <>
      <div className="pt-5">
        <h1>Testimonials</h1>
      </div>

      <div
        id="carouselMultiItemExample"
        className="carousel-slide carousel-dark text-center pt-5"
        data-mdb-carousel="init"
        data-mdb-ride="carousel"
      >
        <div className="d-flex justify-content-center mb-4">
          <button
            className="carousel-control-prev position-relative"
            type="button"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next position-relative"
            type="button"
            onClick={handleNext}
            disabled={currentIndex >= testimonials.length - 3}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className="carousel-inner py-4">
          <div className="carousel-item active">
            <div className="container">
              <div className="row">
                {testimonials
                  .slice(currentIndex, currentIndex + 3)
                  .map((testimonial, index) => (
                    <div key={index} className="col-lg-4">
                      {/* <img
                        className="rounded-circle shadow-1-strong mb-4"
                        src={testimonial.avatarUrl}
                        alt="avatar"
                        style={{ width: 150 }}
                      /> */}
                      <h5 className="mb-3">{testimonial.name}</h5>
                      <p>{testimonial.jobTitle}</p>
                      <p className="text-muted">
                        <i className="fas fa-quote-left pe-2"></i>
                        {testimonial.quote}
                      </p>
                      <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                        {[...Array(Math.floor(testimonial.rating))].map(
                          (_, i) => (
                            <li key={i}>
                              <FontAwesomeIcon icon={faStar} />
                            </li>
                          )
                        )}
                        {testimonial.rating % 1 !== 0 && (
                          <li key="half-star">
                            <FontAwesomeIcon icon={faStarHalfAlt} />
                          </li>
                        )}
                        {[...Array(5 - Math.ceil(testimonial.rating))].map(
                          (_, i) => (
                            <li key={i + Math.ceil(testimonial.rating)}>
                              <FontAwesomeIcon
                                icon={faStar}
                                style={{ color: "lightgray" }}
                              />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
