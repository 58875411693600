import React from "react";
import "./TextBanner.css";

function TextBanner() {
  return (
    <>
      <div className="parentElement">
        <h2 className="topText">MOUNTAIN TRAILERS VENTURES</h2>
        <h1>UNMATCHED QUALITY</h1>
      </div>
    </>
  );
}

export default TextBanner;
