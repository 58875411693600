import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import trailerImage from "./Assets/Misc/UTVs on Trailer.jpg"; // Import the image for the first column

function CompanyMission() {
  return (
    <Container className="py-5">
      <Row className="align-items-center">
        <Col md={6} className="text-center">
          <h2 className="mb-2 text-primary" style={{ fontSize: "2.5rem" }}>
            Helping You Get the Job Done
          </h2>
          <p className="lead">
            We are a family-owned and operated trailer company based in Mills
            River, North Carolina. We offer a wide variety of trailers to meet
            your needs, from utility and equipment trailers to gooseneck and
            enclosed trailers. Whether for personal or commercial use, we have
            the perfect trailer for you. Our trailers are built to last.
          </p>
          <Link to="/about">
            <Button variant="primary" className="text-white">
              Learn More About Us
            </Button>
          </Link>
        </Col>
        <Col md={6} className="mt-5">
          <img
            src={trailerImage}
            alt="Trailers for any job"
            className="img-fluid"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default CompanyMission;
