import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import trailerImage from "./Assets/Misc/Lanscape dump trailer.jpg"; // Import the image

function ManuHighlights() {
  return (
    <Container className="text-center py-5">
      <Row>
        <Col xs={12} md={6}>
          <Image src={trailerImage} alt="High-quality trailers" fluid />
        </Col>
        <Col xs={12} md={6}>
          <h2 className="mb-2 text-primary" style={{ fontSize: "2.5rem" }}>
            Built to Haul. Built to Last.
          </h2>
          <p className="lead">
            Our trailers are meticulously crafted with the toughest materials
            and innovative designs to handle even the most demanding jobs.
            Discover the Mountain Trailer difference and get a quote today.
          </p>
          <Link
            to="/contact"
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            <Button variant="primary" className="text-white">
              Contact Us Now!
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default ManuHighlights;
