import React, { useState } from "react";
import { Container, Card, Row, Col, Modal, Button } from "react-bootstrap";

import "./TrailerCards.css";
import cardImage1 from "./Assets/Car Hauler Pics/Photo May 06 2024, 5 06 42 PM.jpg";
import cardImage2 from "./Assets/Dump Trailer Pics/2023_Covered_Wagon_Trailers_7x14_TA_14K_Dump_Trailer_muBPhA.jpg";
import cardImage3 from "./Assets/EnclosedTrailerPics/Photo Apr 27 2024, 5 11 34 PM.jpg";
import cardImage4 from "./Assets/Equip Trailer Pics/Photo May 06 2024, 5 09 09 PM (1).jpg";
import cardImage5 from "./Assets/Gooseneck Trailer Pics/Photo Apr 27 2024, 5 10 52 PM.jpg";
import cardImage6 from "./Assets/Pintle Hitch Pics/2023_Big_Tex_Trailers_22PH-25BK5MR_Pintle_Hitch__Equipment_Trailer_7xawUa_overlay_1675269551.jpg";
import cardImage7 from "./Assets/Utility Trailer Pics/Photo Apr 23 2024, 4 30 57 PM.jpg";
import cardImage8 from "./Assets/Custom Pics/features.webp";
import Finance from "./Assets/Finance/financeoptions.png";

function TrailerCards() {
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [hoveredCard, setHoveredCard] = useState(null);

  const cardData = [
    {
      image: cardImage1,
      path: "carhauler",
      title: "Car Hauler",
      description:
        "Our car hauler trailers are built to last, constructed using premium materials such as high-strength steel or aluminum. These materials ensure that our trailers can withstand the weight and stress of transporting vehicles while maintaining structural integrity over time. Craftsmanship is at the heart of our trailer designs. Our skilled technicians and engineers pay meticulous attention to detail in every aspect of construction, from the chassis to the braking system. This focus on quality craftsmanship results in trailers that meet or exceed industry standards for safety and performance. We prioritize using top-notch components, including heavy-duty axles, high-performance tires, and robust hitching systems, to create trailers that are reliable and durable. Our commitment to quality extends to rigorous testing and inspections, ensuring that each trailer leaving our facility meets our high standards of excellence. When you choose our car hauler trailers, you're choosing reliability, durability, and quality craftsmanship for all your vehicle transportation needs. Experience the difference with our trailers today.",
    },
    {
      image: cardImage2,
      path: "dump",
      title: "Dump",
      description:
        "Our dump trailers are built to handle heavy loads and tough jobs with ease. Constructed using high-quality materials such as reinforced steel or aluminum, these trailers are engineered to withstand the rigors of hauling and dumping materials like gravel, sand, or debris. Craftsmanship is a cornerstone of our dump trailer designs. Our skilled technicians and engineers pay meticulous attention to detail during construction, ensuring that every weld, joint, and component is precisely crafted for optimal performance and longevity. We prioritize quality in every aspect of our dump trailers. From heavy-duty axles and rugged tires to robust hydraulic systems and durable bed materials, our trailers are built to last and excel in demanding work environments. Each dump trailer undergoes thorough testing and inspections to guarantee its reliability and durability. When you choose our dump trailers, you're choosing a dependable solution for your hauling and dumping needs, backed by our commitment to excellence and customer satisfaction. Experience the difference with our quality dump trailers today.",
    },
    {
      image: cardImage3,
      path: "enclosed",
      title: "Enclosed",
      description:
        "Our enclosed trailers are built to protect your valuable cargo from the elements and ensure safe transport. Constructed using premium materials such as reinforced steel or aluminum, these trailers feature a sturdy frame and weather-resistant design. Craftsmanship is at the core of our enclosed trailer lineup. Skilled technicians and engineers meticulously craft each trailer to meet our strict standards for quality and performance. From the precision welding to the seamless integration of components, our trailers are built to last. We prioritize functionality and durability in our enclosed trailers, incorporating features like secure locking mechanisms, durable flooring, and customizable interior options. Whether you need to transport vehicles, equipment, or goods, our enclosed trailers provide a reliable solution. Choose our enclosed trailers for unmatched protection, durability, and craftsmanship. Experience the difference with our premium trailers today.",
    },
    {
      image: cardImage4,
      path: "equipment",
      title: "Equipment",
      description:
        "Our equipment trailers are built to handle the toughest jobs with ease. Constructed using robust materials such as reinforced steel or heavy-duty aluminum, these trailers feature a rugged frame and reliable components. Craftsmanship is a key aspect of our equipment trailer designs. Skilled technicians and engineers meticulously craft each trailer to ensure superior strength, stability, and performance. From the reinforced axles to the sturdy decking, our trailers are built to last. We prioritize functionality and durability in our equipment trailers, incorporating features like adjustable ramps, tie-down points, and optional equipment upgrades. Whether you're hauling machinery, vehicles, or construction materials, our equipment trailers deliver unmatched reliability. Choose our equipment trailers for exceptional durability, craftsmanship, and performance. Experience the difference with our rugged trailers today.",
    },
    {
      image: cardImage5,
      path: "gooseneck",
      title: "Gooseneck",
      description:
        "Our gooseneck trailers are built to handle large loads with ease. Constructed using high-strength materials such as reinforced steel or aluminum, these trailers feature a robust gooseneck hitch for enhanced stability and towing capacity. Craftsmanship is a cornerstone of our gooseneck trailer designs. Skilled technicians and engineers meticulously craft each trailer to ensure optimal strength, durability, and performance. From the precision welding to the seamless integration of components, our trailers are built to exceed expectations. We prioritize functionality and safety in our gooseneck trailers, incorporating features like heavy-duty axles, adjustable couplers, and reliable braking systems. Whether you're hauling livestock, equipment, or cargo, our gooseneck trailers deliver unmatched reliability and performance. Choose our gooseneck trailers for superior stability, durability, and craftsmanship. Experience the difference with our premium trailers today.",
    },
    // {
    //   image: cardImage6,
    //   path: "pintlehitch",
    //   title: "Pintle Hitch",
    //   description:
    //     "Our pintle hitch trailers are built to handle challenging towing tasks with ease. Constructed using high-quality materials such as reinforced steel or heavy-duty aluminum, these trailers feature a robust pintle hitch for secure attachment and towing stability. Craftsmanship is a key focus in our pintle hitch trailer designs. Skilled technicians and engineers meticulously craft each trailer to ensure optimal strength, durability, and performance. From the rugged frame to the reliable components, our trailers are built to withstand demanding towing applications. We prioritize functionality and reliability in our pintle hitch trailers, incorporating features like adjustable hitches, reinforced decking, and heavy-duty suspension systems. Whether you're towing equipment, machinery, or vehicles, our pintle hitch trailers deliver exceptional performance. Choose our pintle hitch trailers for unmatched towing capability, durability, and craftsmanship. Experience the difference with our reliable trailers today.",
    // },
    {
      image: cardImage7,
      path: "utility",
      title: "Utility",
      description:
        "Our utility trailers are built to handle various loads with ease. Constructed using durable materials such as reinforced steel or aluminum, these trailers feature a sturdy frame and reliable components for dependable performance. Craftsmanship is a central focus in our utility trailer designs. Skilled technicians and engineers meticulously craft each trailer to ensure durability, strength, and functionality. From the reinforced construction to the practical features, our trailers are built to meet your hauling requirements. We prioritize versatility and reliability in our utility trailers, incorporating features like removable sides, multiple tie-down points, and customizable options. Whether you're transporting landscaping materials, furniture, or equipment, our utility trailers provide a dependable solution. Choose our utility trailers for unmatched versatility, durability, and craftsmanship. Experience the difference with our reliable trailers today.",
    },
    // {
    //   image: cardImage8,

    //   title: "Customize Build",
    //   description: "This will be a link to Customize Build page",
    // },

    // {
    //   image: Finance,
    //   title: "Financing Available",
    //   description: "This will be a link to Financing page",
    // },
  ];

  const handleCardClick = (card) => {
    setModalContent(card);
    setModalShow(true);
  };

  const handleMouseEnter = (card) => {
    console.log(hoveredCard);
    setHoveredCard(card);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const styles = `
    .card {
      cursor: pointer;
      overflow: hidden;
      transition: transform 0.2s ease-in-out;
      min-Height: 50vh;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4); /* Add box shadow */
      flex: 1 0 calc(33.33% - 20px); /* Responsive sizing using flexbox */
      margin: 10px; /* Add margin for spacing */
    }

    .card:hover {
      transform: scale(1.1);
    }

    .card-img {
      object-fit: cover;
      max-Height: 200px;
    }

    @media (max-width: 768px) {
      .card {
        flex: 1 0 calc(50% - 20px); /* Adjust sizing for smaller screens */
      }
    }

    @media (max-width: 576px) {
      .card {
        flex: 1 0 100%; /* Make cards take up full width on extra small screens */
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <Container>
        <Row>
          {cardData.map((card, index) => (
            <Col key={index} md={4} className="mb-4">
              <Card
                onClick={() => handleCardClick(card)}
                onMouseEnter={() => handleMouseEnter(card)}
                onMouseLeave={handleMouseLeave}
                className="card"
              >
                <Card.Img variant="top" src={card.image} className="card-img" />
                <Card.Body>
                  <Card.Title className="text-center">{card.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal show={modalShow} onHide={() => setModalShow(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={modalContent.image}
            alt={modalContent.title}
            style={{
              maxWidth: "100%",
            }}
          />
          <p>{modalContent.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TrailerCards;
