import React from "react";
import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import review1 from "./Assets/TestimonialPics/depositphotos_218271242-stock-photo-smiling-bearded-man-driving-pickup.jpg";

function PintleHitch() {
  const testimonials = [
    {
      name: "Anna Deynah",
      jobTitle: "UX Designer",
      quote:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.",
      rating: 5,
      avatarUrl: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp",
    },
    {
      name: "Cal Deynah",
      jobTitle: "Business Owner",
      quote:
        '"I couldn\'t be happier with my trailer purchase from this Mountain Trailer Ventures. The selection was fantastic, the prices were competitive, and the customer service was top-notch. I highly recommend them to anyone in the market for a new trailer!" - Cal D.',
      rating: 3.5,
      avatarUrl: { review1 }, // Assuming review1 holds a valid image URL
    },
    // Add more testimonials objects here
  ];

  return (
    <>
      <div className="pt-5">
        <h1>Pintle Hitch Trailers</h1>
      </div>

      {/* <div className="my-5 text-center">
        <h1>Testimonials</h1>
      </div> */}
      <Carousel
        id="carouselMultiItemExample"
        data-mdb-carousel-init
        className="carousel slide carousel-dark text-center"
        data-mdb-ride="carousel"
      >
        <div className="d-flex justify-content-center mb-4">
          <button
            className="carousel-control-prev position-relative"
            type="button"
            data-mdb-target="#carouselMultiItemExample"
            data-mdb-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next position-relative"
            type="button"
            data-mdb-target="#carouselMultiItemExample"
            data-mdb-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        {/* Inner */}
        <div py-4>
          {testimonials.map((testimonial) => (
            <Carousel.Item key={testimonial.name}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <img
                      className="rounded-circle shadow-1-strong mb-4"
                      src={testimonial.avatarUrl}
                      alt="avatar"
                      style={{ width: 150 }}
                    />
                    <h5>{testimonial.name}</h5>
                    <p>{testimonial.jobTitle}</p>
                    <p className="text-muted">
                      <i className="fas fa-quote-left pe-2"></i>
                      {testimonial.quote}
                    </p>
                    <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                      {[...Array(Math.floor(testimonial.rating))].map(
                        (_, i) => (
                          <li key={i}>
                            <FontAwesomeIcon icon={faStar} />
                          </li>
                        )
                      )}
                      {testimonial.rating % 1 !== 0 && (
                        <li key="half-star">
                          <FontAwesomeIcon icon={faStarHalf} />
                        </li>
                      )}
                      {[...Array(5 - Math.ceil(testimonial.rating))].map(
                        (_, i) => (
                          <li key={i + Math.ceil(testimonial.rating)}>
                            <FontAwesomeIcon
                              icon={faStar}
                              style={{ color: "gray" }}
                            />
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </div>
      </Carousel>
    </>
  );
}

export default PintleHitch;
