import React from "react";
import customImg from "./Assets/CustomBuild/rizky-nuriman-3oeKR5qNIeA-unsplash.jpg";
import customImg2 from "./Assets/CustomBuild/morteza-mohammadi-ZkvJnta6bAI-unsplash.jpg";
import { Row, Col, Container } from "react-bootstrap";

const imgStyle = {
  width: "100%",
  height: "300px", // Set a fixed height for the images
  objectFit: "cover",
};

function CustomBuild() {
  return (
    <Container className="py-5">
      <Row className="">
        <Col lg={3} className="d-flex align-items-center">
          <Container>
            <img src={customImg2} alt="custom build options" style={imgStyle} />
          </Container>
        </Col>
        <Col lg={6}>
          <Container className="px-5 lead">
            <h2
              className="mb-2 text-center text-primary"
              style={{ fontSize: "2.5rem" }}
            >
              Build Your Dream Trailer
            </h2>
            <p className="text-center">
              Don't settle for ordinary. Design a trailer that perfectly fits
              your needs and preferences. From utility trailers to car haulers,
              our custom build service brings your vision to life.
            </p>
            <ul>
              <li>Specialized trailers for any purpose</li>
              <li>Select size, features, and design elements</li>
              <li>Attention to detail and quality craftsmanship</li>
            </ul>
          </Container>
        </Col>
        <Col lg={3} className="d-flex align-items-center">
          <Container>
            <img src={customImg} alt="custom build options" style={imgStyle} />
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default CustomBuild;
