import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./Assets/MTV-Logo/MTV_Logo_V4.png";

function Footer() {
  const footerStyle = {
    backgroundColor: "rgba(40,65,100, 0.95)",
  };

  return (
    <footer className="text-white p-1" style={footerStyle}>
      <Container className="py-4">
        <Row>
          <Col className="d-flex justify-content-center">
            <img
              src={Logo}
              alt="Mountain Trailer Ventures Logo"
              width="250px"
            />
          </Col>
          <Col className="py-3">
            <h4>Contact Us</h4>
            <p>Mountain Trailer Ventures, LLC</p>
            <p>Address: 3350 N. Mills River Rd., Mills River, NC</p>
            <p>Email: mountaintrailerventures307@gmail.com</p>
            <p>Phone: 828-230-7702</p>
          </Col>
          <Col className="p-4">
            <h4>Quick Links</h4>

            <dl>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/financing">Financing</Link>
              </li>
              <li>
                <Link to="/testimonials">Testimonials</Link>
              </li>

              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </dl>
          </Col>
        </Row>
        <hr />
        <p className="text-center">
          &copy; {new Date().getFullYear()} Mountain Trailer Ventures. All
          rights reserved.
        </p>
      </Container>
    </footer>
  );
}

export default Footer;
