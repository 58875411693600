import React from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import CTA from "./CTA";
import utility1 from "./Assets/Utility Trailer Pics/Photo Apr 23 2024, 4 29 24 PM.jpg";
import utility2 from "./Assets/Utility Trailer Pics/Photo Apr 23 2024, 4 31 12 PM.jpg";
import utility3 from "./Assets/Utility Trailer Pics/Photo Apr 23 2024, 4 33 44 PM.jpg";
import utility4 from "./Assets/Utility Trailer Pics/Photo Apr 23 2024, 4 30 57 PM.jpg";
import utility5 from "./Assets/Utility Trailer Pics/Photo Apr 23 2024, 4 31 26 PM.jpg";

function Utility() {
  return (
    <>
      <div className="pt-5 pb-2">
        <h1>Utility Trailers</h1>
      </div>

      <Carousel>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={utility1}
            alt="Gooseneck trailer"
          />
          {/* <Carousel.Caption>
            <h3>Gooseneck Trailers</h3>
            <p>The best choice for heavy-duty hauling.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={utility2}
            alt="Equipment trailer"
          />
          {/* <Carousel.Caption>
            <h3>Equipment Trailers</h3>
            <p>Perfect for transporting machinery and tools.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={utility3}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={utility4}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={utility5}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>

      <div className="my-5" style={{ minHeight: "300px" }}>
        <Container className="w-75 mb-4">
          <div className="utility-trailer-description text-center">
            <h2 className="text-primary">
              Versatile Hauling Solution - Utility Trailers
            </h2>
            <p className="lead">
              Our top-of-the-line utility trailers are designed to provide
              versatility and efficiency for all your hauling needs. Whether
              you're a homeowner tackling weekend projects or a professional
              contractor transporting equipment, we have the perfect trailer for
              your needs.
            </p>
          </div>
        </Container>
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary">
                Size and Capacity Specifications:
              </h2>
              <p>
                Utility trailers come in various sizes to accommodate different
                hauling requirements. Here's an overview of common
                configurations:
              </p>
              <ul>
                <li>
                  **Single-axle utility trailer:** Typically 8' to 12' long and
                  can handle loads up to 3,000 lbs.
                </li>
                <li>
                  **Tandem-axle utility trailer:** Usually 12' to 16' long and
                  capable of handling loads up to 5,000 lbs., providing added
                  stability and capacity for heavier loads.
                </li>
                <li>
                  **Flatbed utility trailer:** Offers versatility for hauling
                  various items such as ATVs, lawn equipment, or building
                  materials.
                </li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Standard Features:</h2>
              <ul>
                <li>Heavy-duty steel frame construction for durability</li>
                <li>Pressure-treated wood deck for longevity</li>
                <li>LED lighting for visibility and safety</li>
                <li>
                  Rear ramp gate or tailgate for easy loading and unloading
                </li>
                <li>Leaf spring suspension for a smooth ride</li>
                <li>Available in various deck lengths and widths</li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Optional Additions:</h2>
              <ul>
                <li>Spare tire and mount</li>
                <li>Toolbox for storage of equipment and accessories</li>
                <li>Side rails for added cargo security</li>
                <li>Brake upgrade for increased stopping power</li>
                <li>Custom paint and branding options</li>
                <li>Utility rack for transporting ladders or long items</li>
                <li>Winch for loading heavy equipment</li>
              </ul>
            </Col>
          </Row>

          <Container>
            <div>
              <h4 className="text-primary">
                77x10 Utility TT 77x12 Utility TT 77x14 Utility TT
              </h4>
              <p>
                Specifications: 3.5K GAWR Idler Axle Multi-Leaf Double Eye
                Springs 2" Ball A-Frame Hitch 2K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 3" Channel Wrap Tongue 1' Tall
                Tubing Sides and Top Rail 4' Tall HD Tubing Gate ST205/75R15
                Radial Tires and Silver Modular Rims 4 D-rings Spare Tire Mount
                Standard Teardrop Fenders LED Lights Paint Color? Pinstripe
                Color? Dovetail?
              </p>
            </div>

            <div>
              <h4 className="text-primary">
                77x10 Utility TT, 2' Mesh Sides 77x12 Utility TT, 2' Mesh Sides
                77x14 Utility TT, 2' Mesh Sides
              </h4>
              <p>
                Specifications: 3.5K GAWR Idler Axle Multi-Leaf Double Eye
                Springs 2" Ball A-Frame Hitch 2K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 3" Channel Wrap Tongue 2' Tall
                Tubing Sides and Top Rail with Mesh 4' Tall HD Tubing Gate
                ST205/75R15 Radial Tires and Silver Modular Rims 4 D-rings Spare
                Tire Mount Standard Teardrop Fenders LED Lights Paint Color?
                Pinstripe Color? Dovetail?
              </p>
            </div>

            <div>
              <h4 className="text-primary">
                77x12 Utility TT, 3' Mesh Sides 77x14 Utility TT, 3' Mesh Sides
              </h4>
              <p>
                Specifications: 3.5K GAWR Idler Axle Multi-Leaf Double Eye
                Springs 2" Ball A-Frame Hitch 2K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 3" Channel Wrap Tongue 3' Tall
                Tubing Sides and Top Rail with Mesh 4' Tall HD Tubing Gate
                ST205/75R 15 Radial Tires and Silver Modular Rims 4 D-rings
                Spare Tire Mount Standard Teardrop Fenders LED Lights Paint
                Color? Pinstripe Color? Dovetail?
              </p>
            </div>

            <div>
              <h4 className="text-primary">
                77x12 LW 2' Mesh 2990K 77x14 LW 2' Mesh 2990K
              </h4>
              <p>
                Specifications: 3.5K Idler Axle Multi-Leaf Double Eye Springs NO
                Dovetail 2" Ball A-Frame Coupler 2K Jack Set in Coupler with
                Jack Foot Standard Fenders 4' Angle Gate 2x2 Angle Frame and
                Crossmembers 3X2 Angle A-Frame Tongue 24" Angle Sides and
                Uprights 2x8 Treated Wood Floor ST235/75D15 Bias Tires and
                Silver Modular Rims LED Lights in Metal Boxes Black Paint Red
                Pinstripes
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                77x12 Tandem TT 77x14 Tandem TT 77x16 Tandem TT 82x12 Tandem TT
                82x14 Tandem TT 82x16 Tandem TT
              </h4>
              <p>
                Specifications: (2) 3.5K GAWR Axles (1 Brake) Multi-Leaf Double
                Eye Springs 2" Ball A-Frame Hitch 5K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 4" Channel Wrap Tongue 1' Tall
                Tubing Sides and Top Rail 4' Tall HD Tubing Gate ST205/75R 15
                Radial Tires and Silver Modular Rims 4 D-rings Spare Tire Mount
                Standard Teardrop Fenders LED Lights DOT Approved Break-Away
                Red, Gray or Black Paint Specify Pinstripe Color
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                77x12 Tandem TT, 1' Mesh Sides 77x14 Tandem TT, 1' Mesh Sides
                77x16 Tandem TT, 1' Mesh Sides
              </h4>

              <p>
                Specifications: (2) 3.5K GAWR Axles (1 Brake) Multi-Leaf Double
                Eye Springs 2" Ball A-Frame Hitch 5K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 4" Channel Wrap Tongue 1' Tall
                Tubing Sides and Top Rail with Mesh 4' Tall HD Tubing Gate
                ST205/75R15 Radial Tires and Silver Modular Rims 4 D-rings Spare
                Tire Mount Standard Teardrop Fenders LED Lights DOT Approved
                Break-Away
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                77x12 Tandem TT, 2' Mesh Sides 77x14 Tandem TT, 2' Mesh Sides
                77x16 Tandem TT, 2' Mesh Sides
              </h4>
              <p>
                Specifications: (2) 3.5K GAWR Axles (1 Brake) Multi-Leaf Double
                Eye Springs 2" Ball A-Frame Hitch 5K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 4" Channel Wrap Tongue 2' Tall
                Tubing Sides and Top Rail with Mesh 4' Tall HD Tubing Gate
                ST205/75R15 Radial Tires and Silver Modular Rims 4 D-rings Spare
                Tire Mount Standard Teardrop Fenders LED Lights DOT Approved
                Break-Away Paint Color? Pinstripe Color? Dovetail ?
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                77x12 Tandem TT, 3' Mesh Sides 77x14 Tandem TT, 3' Mesh Sides
                77x16 Tandem TT, 3' Mesh Sides
              </h4>
              <p>
                Specifications: (2) 3.5K GAWR Axles (1 Brake) Multi-Leaf Double
                Eye Springs 2" Ball A-Frame Hitch 5K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 4" Channel Wrap Tongue 3' Tall
                Tubing Sides and Top Rail with Mesh 4' Tall HD Tubing Gate
                ST205/75R 15 Radial Tires and Silver Modular Rims 4 D-rings
                Spare Tire Mount Standard Teardrop Fenders LED Lights DOT
                Approved Break-Away Dovetail? Paint Color? Pinstripe Color?
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                77x12 Tandem TT, 4* Mesh Sides 77x14 Tandem TT, 4' Mesh Sides
                77x16 Tandem TT, 4* Mesh Sides
              </h4>
              <p>
                Specifications: (2) 3.5K GAWR Axles (1 Brake) Multi-Leaf Double
                Eye Springs 2" Ball A-Frame Hitch 5K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 4" Channel Wrap Tongue 4' Tall
                Tubing Sides and Top Rail with Mesh 4' Tall HD Tubing Gate
                ST205/75R 15 Radial Tires and Silver Modular Rims 4 D-rings
                Spare Tire Mount Standard Teardrop Fenders LED Lights DOT
                Approved Break-Away Dovetail? Paint Color? Pinstripe Color?
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                77x14 Tandem TT, 2' Solid Sides 82x14 Tandem TT, 2' Solid Sides
              </h4>
              <p>
                Specifications: (2) 3.5K GAWR Axles (1 Brake) Multi-Leaf Double
                Eye Springs 2" Ball A-Frame Hitch 5K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 4" Channel Wrap Tongue 2' Tall
                Tubing Sides and Top Rail with 14 Gage Tread Plate Tarp Ties 12"
                Down From Top Rail on All Uprights 4' Tall HD Tubing Gate With
                Mesh ST205/75R15 Radial Tires and Silver Modular Rims 4 D-rings
                Spare Tire Mount Standard Teardrop Fenders LED Lights DOT
                Approved Break-Away Paint Color? Pinstripe Color? Dovetail ?
              </p>
            </div>
            <div>
              <h4 className="text-primary">
                77x16 Tandem TT 4' SS 82x16 Tandem TT 4 SS
              </h4>
              <p>
                Specifications: (2) 3.5K GAWR Axles (1 Brake) Multi-Leaf Double
                Eye Springs 2" Ball A-Frame Hitch 5K Jack Set Back in Tongue 2x3
                Angle Frame and Cross Members 4" Channel Wrap Tongue 4' Tall
                Tubing Sides and Top Rail with Gauge Treadplate Sides 4' Tall HD
                Tubing Gate ST205/75R15 Radial Tires and Silver Modular Rims 4
                D-rings Spare Tire Mount Standard Teardrop Fenders LED Lights
                DOT Approved Break-Away
              </p>
            </div>
          </Container>

          <p className="lead text-center text-primary my-5">
            Contact us today to discuss your specific hauling needs and receive
            a quote for your ideal utility trailer!
          </p>
        </Container>
        <CTA />
      </div>
    </>
  );
}

export default Utility;
