import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import backgroundImage from "./Assets/wes-hicks-ZW6RUvsaFTc-unsplash.jpg";
import { Link } from "react-router-dom";

function HeroSection() {
  const [fadeIn, setFadeIn] = useState(false);
  useEffect(() => {
    // Set the fade-in effect after the component mounts
    setFadeIn(true);
  }, []);

  const heroBG = {
    width: "100vw",
    height: "100vh",
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.75), rgba(0, 0, 25, 0.5)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed", // Make the background image fixed
    display: "flex", // Added flex display
    alignItems: "center", // Center align items vertically
    justifyContent: "center", // Center align items horizontally
    textAlign: "center", // Center align text inside the div
    opacity: fadeIn ? 1 : 0, // Set opacity based on fadeIn state
    transition: "opacity 1s ease-in-out", // Add a transition effect for opacity
  };

  const heroTitle = {
    transform: fadeIn ? "translateY(0%)" : "translateY(-10%)",
    transition: "transform 1.3s ease-in-out",
    fontFamily: "Roboto",
    fontSize: "10vw",
    fontWeight: "bold",
    color: "#fff",
    lineHeight: "1",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.75)",
  };

  return (
    <>
      <div style={heroBG}>
        <div style={heroTitle}>
          <div>Mountain Trailer Ventures</div>
          <Link to="/products">
            <Button
              variant="primary"
              className="text-white p-3 mt-4"
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              Browse All Trailers
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
