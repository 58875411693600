import React from "react";
import FinanceImg from "./Assets/Misc/Utility trailer and truck.jpg";
import { Row, Col, Container } from "react-bootstrap";
import CTA from "./CTA";

function CustomBuild() {
  return (
    <div className="pt-5">
      <h1 className="text-center">Financing</h1>
      <Container fluid>
        <Row className="my-5 align-items-center">
          <Col lg={6}>
            <img
              src={FinanceImg}
              alt="custom build options"
              className="img-fluid"
            />
          </Col>
          <Col lg={6}>
            <Container className="px-4">
              <h2
                className="mb-3 text-primary text-center mb-4"
                style={{ fontSize: "2.5rem" }}
              >
                Financing Available
              </h2>
              <p className="lead">
                We offer a range of flexible financing options to make
                purchasing your ideal trailer convenient and affordable. Whether
                you're looking for a utility trailer, dump trailer, gooseneck or
                a similar trailer, we partner with trusted financial
                institutions to provide competitive rates and terms tailored to
                your budget. With easy online applications and quick approval
                processes, you can hook up to your new trailer sooner than you
                think. Explore our financing options today and take the first
                step towards owning the perfect trailer for your needs.
              </p>
            </Container>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <CTA />
        </Row>
      </Container>
    </div>
  );
}

export default CustomBuild;
