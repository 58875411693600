import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Testimonial from "./Assets/TestimonialPics/depositphotos_218271242-stock-photo-smiling-bearded-man-driving-pickup.jpg";
import Financing from "./Assets/Finance/financeoptions.png";

function Support() {
  return (
    <>
      <Container
        fluid
        className="d-flex justify-content-baseline align-items-center min-vh-100 py-5"
      >
        <Row>
          <Col xs={12} md={6} className="text-center mb-5">
            <img
              className="mb-4"
              src={Testimonial}
              alt="Testimonies "
              style={{ width: "auto", height: "35vh", objectFit: "cover" }}
            />
            <h2 className="mb-2 text-primary" style={{ fontSize: "2.5rem" }}>
              Exceeding Our Customer's Expectations
            </h2>
            <p className="lead">
              We understand the importance of reliable trailers for your
              business. Our trailers are built to last. Don't just take our word
              for it, hear from real customers.
            </p>
            <Link to="/testimonials">
              <Button variant="primary" className="text-white">
                Read Customer Experience Stories
              </Button>
            </Link>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <img
              className="mb-4"
              src={Financing}
              alt="Trailer financing options available"
              style={{ width: "auto", height: "35vh", objectFit: "cover" }}
            />
            <h2 className="mb-2 text-primary" style={{ fontSize: "2.5rem" }}>
              Financing Made Easy
            </h2>
            <p className="lead">
              Get the trailer you need now with our flexible financing and
              purchasing options.
            </p>
            <Link to="/contact">
              <Button variant="primary">
                Contact Us For Financing Options
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Support;
